import { render, staticRenderFns } from "./Sale.vue?vue&type=template&id=1c8b3178&scoped=true&"
import script from "./Sale.vue?vue&type=script&lang=js&"
export * from "./Sale.vue?vue&type=script&lang=js&"
import style0 from "./Sale.vue?vue&type=style&index=0&id=1c8b3178&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c8b3178",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardText,VCol,VCombobox,VContainer,VForm,VIcon,VRadio,VRadioGroup,VRow,VSimpleTable,VTextField,VTextarea,VToolbar,VToolbarTitle})
